import React from 'react'
import Layout from '../components/layout'
import NewsModule from '../components/NewsModule'



export default () => {



    return (
        <Layout>
            <NewsModule />
        </Layout>

    )

}